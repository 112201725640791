<!-- Importacion Presupuestos Funerarias a Presupuestos Expedientes -->

<template>
  <div class="funesPresImportF" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">       
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor">

          <!-- <div v-html="info"></div> -->
                    
          <div style="display:flex; align-items:center">
        
            <v-select 
              style="width:80px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              v-bind="$select"
              v-model="ct.ano[2]"
              :items="itemsAno"
              :label="ct.ano[1]"
              item-value="ano"
              item-text="label"
              @change="eventFiltro('buscar')"
              :disabled="disabledAno">            
            </v-select>
          
            <v-select
              style="width:380px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              v-bind="$select"
              v-model="ct.descrip[2]"
              :items="filtroPresupuestos"
              label="Tipo presupuesto"
              item-value="id"
              item-text="label"
              @change="eventFiltro('buscar')">
            </v-select>
          
            <v-select 
              style="width:100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              v-bind="$select"
              v-model="ct.cia_id[2]"
              :items="itemsCia"
              :label="ct.cia_id[1]"
              item-value="id"
              item-text="label"
              @change="eventFiltro('buscar')"
              :disabled="disabledCia">            
            </v-select>
    
          </div>


          <!-- Grid -->
          <baseGridMD
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"           
            dense
            @onEvent="execAccion">

              <!-- Slot columna header 'dif' -->
              <template v-slot:xcia="{ item }">
                {{ item.ciaName }}
              </template>

              <!-- Slot columna header 'preferente' -->
              <template v-slot:preferente="{ item }">
                <span style="color:green;font-weight: bold">{{ item["preferente"] === "1" ? "P" : "" }}</span>
              </template>              

              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">               
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>
          </baseGridMD>
        </div>
      </v-sheet>
    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto },
    props: {
      filtroImportServicio:{}
    },

    data() {
      return {
        stIni: {
          api: "funesPresF",
          titulo:"Importación de Presupuestos", 
          name: "funesPresF",
          mView: '',
          pView:[]
        },  

        ST_tipo_presup: null,
        itemsAno: null,
        itemsCia: null,
        itemsDescripcion: null,
        
        // Permitir cambiar de cia en filtro
        disabledCia:false,
        disabledAno:false
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // Cabecera Finder
        this.headers= [{
              // añadir en name los nombres de las cabeceras que coincidiran con las busquedas
              // si solo hubiera un elemento la cabecera tomará este por defecto 
              // Si no encontrara el nombre pondría el elemento 0
              name:[],
              fields:[
                { text: "ACCIONES", value: "acciones", sortable: false, slot:true, width: "10%" },
                { text: "AÑO", value: "ano", width:"5%" },
                { text: "CIA", value: "xcia", slot:true, width:"5%" },
                { text: "TIPO", value: "name_id_presciac", width:"15%" },
                { text: "MUNICIPIO", value: "atlas", width:"20%" },
                { text: "PFT", value: "preferente", slot:true, width:"5%" },
                { text: "DESCRIPCIÓN", value: "descrip", width:"30%" },

                // { text: "AÑO ANT.", value: "imp_ant" },
                // { text: "DIF %", value: "dif" },
                { text: "P.DIRECTO", value: "sup_pd", width:"5%" },
                // { text: "IMP.CIA", value: "imp_cia" },
                { text: "IMPORTE", value: "imp", width:"5%" },              
                
            ]}          
        ];      

        this.headerGrid= this.headerSet();     

        // defino autocarga
        this.autoLoad= 'buscar';

        // pendiente: por que aqui y no en la base??????
        // guardo Schema del store en variable local
        /* this.schema = this.$store.state[this.stName].schema;
        console.log('SCHEMAA: ', this.stName, this.schema); */
        // obtengo items tipo presupuestos
        this.ST_tipo_presup = this.$store.state.datos_iniciales.tipo_presup;
        this.filtroPresupuestos=this.$store.state.datos_iniciales.filtroPresupuestos;

        // selecciono el año actual
        this.$set(this.ct.ano, 2, new Date().getFullYear());

        //
        if (this.filtroImportServicio) {
            this.$set(this.ct.cia_id, 2, this.filtroImportServicio.cia_id?this.filtroImportServicio.cia_id:'');
            this.disabledCia=true;
            //this.disabledAno=true;
            this.disabledAno=this.rol=='gestion'?false:true;
            //nueva linea para añadir año a importacion
            //if (this.filtroImportServicio.fhhr) this.schema.ctrls.ano.value=this.filtroImportServicio.fhhr.substr(0,4);
            if (this.filtroImportServicio.fhhr) this.$set(this.ct.ano, 2, this.filtroImportServicio.fhhr.substr(0,4));
        }
        
        // array de años
        this.itemsAno = [
          { id: "", ano: "", label: "TODOS" },
          { id: "1", ano: 2020, label: "2020" },
          { id: "2", ano: 2021, label: "2021" },
          { id: "3", ano: 2022, label: "2022" }
        ];

        // array de compañías
        this.itemsCia = [
          { id: "", label: "TODAS LAS CIAS" },
          { id: "83", label: "MAPFRE" },
          { id: "50165", label: "KUTXABANK" },
          // { id: "50508", label: "KUTXABANK" }
        ];
    
      },

     
      //
      /* row_dblclick(evt) {
        if (this.is_padreEdit) {
          this.$root.$alert.open("Cancele o Guarde para acceder", "info");
          return;
        }
        this.reactividad_M({
          accion: this.Entorno.grid.row.dblclick,
          row: evt.row
        });
      }, */

      // Campos personalizados GRID
      //
     


      
      // pendiente:
      // lo deberia de devolver la sql con un join en la carga de registros
      //  Devuelvo el nombre del tipo de presupuesto
      /* get_presciac(item) {
        const result = this.ST_tipo_presup.find(
          elem => elem.id === item.id_presciac
        ).name;
        return result == undefined ? "" : result;
      }, */
      // recargar finder
      /* reload() {
        //this.get_records(this.Entorno.grid.autoload? 'ini' : '');
      }, */

      /* extraer(evt) {      
        this.$emit('onEvent', evt);
      }, */
      
    },


    computed: {
      
      masterField() {
        if (!this.recordAux) return null;
        return this.recordAux.id;
      }

    }
     
  };

</script>
